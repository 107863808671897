<script lang="ts" setup>
import prefectures from "~/assets/data/prefectures.json";
import { ref } from "vue";
import { useSearchFormStore } from "@/stores/search_form";

const appConfig = useAppConfig();
const searchFormStore = useSearchFormStore();

function onSubmit(e) {
  navigateTo(searchFormStore.route);
}
</script>

<template lang="pug">
v-row.top-header(align-content="center")
  v-col(cols="12")
    h1.copy さあ、愛犬とおでかけしよう！
    h2.subtitle ペット同伴可の施設が見つかる愛犬家のための検索サイト
  v-col
    v-sheet(
      class="rounded-lg py-3 form-bg"
      itemscope
      itemtype="https://schema.org/WebSite"
    )
      meta(
        itemprop="url"
        :content="`http://${appConfig.SITE_HOST_NAME}`"
      )
      v-form(
        @submit.prevent
        itemprop="potentialAction"
        itemscope
        itemtype="https://schema.org/SearchAction"
      )
        v-tabs(
          v-model="searchFormStore.placeType"
          grow
          slider-color="teal-lighten-3"
          class="rounded-lg"

        )
          v-tab(color="white" rounded="xs" value="dog-run" v-bind="slotProps" style="width: 110px")
            v-icon(icon="mdi-dog-side")
            b ドッグラン
          v-tab(color="white" rounded="xs" value="dog-cafe" v-bind="slotProps" style="width: 110px")
            v-icon(icon="mdi-coffee")
            b カフェ
          v-tab(color="white" rounded="xs" value="dog-hotel" v-bind="slotProps" style="width: 110px")
            v-icon(icon="mdi-bed")
            b ホテル
        v-tabs(
          v-model="searchFormStore.searchType"
          grow
        )
          v-tab(color="white" rounded="xs" value="keyword") キーワード
          PrefectureFilterDialog(
            :placeType="searchFormStore.placeType"
          )
            template(#dialog-activator="slotProps")
              v-tab(color="white" rounded="xs" value="pref" v-bind="slotProps") 都道府県
          TagFilterDialog(
            :placeType="searchFormStore.placeType"
          )
            template(#dialog-activator="slotProps")
              v-tab(color="white" rounded="xs" value="tag" v-bind="slotProps") 特徴
        meta(
          itemprop="target"
          :content="`https://${appConfig.SITE_HOST_NAME}/dog-run/search?k={search_term_string}`"
        )
        v-text-field(
          v-model="searchFormStore.keyword"
          variant="solo"
          placeholder="施設名、エリア、キーワードなど"
          update:modelValue="searchFormStore.setKeyword"
          :hide-details="true"
          itemprop="query-input"
          type="text"
          name="search_term_string"
        )
        v-btn(class="my-1" outlined @click="onSubmit" type="submit" size="x-large" color="#50b460" block): b(class="text-white") 検索

</template>

<style lang="scss">
.top-header {
  background-color: #002f2ab8;
  background-image: url("/images/top-bg-img.jpg");
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center center;
  height: 400px;
  text-align: center;
  text-align: -webkit-center;

  h1.copy {
    color: white;
    font-size: 1.2rem;
  }
  h2.subtitle {
    color: white;
    font-size: 0.8rem;
    line-height: 2rem;
  }

  .v-selection-control-group {
    justify-content: center;
  }
  .v-slide-group {
    overflow: visible;

    .v-slide-group__container {
      overflow: visible;
      contain: none;
    }
  }
  .v-tab {
    border: 2px solid white;
    border-right: none;
    color: white;
    opacity: 1;
    font-weight: bold;
    &.v-tab--selected {
      background-color: white;
      color: #595757 !important;

      .v-tab__slider {
        opacity: 0;
      }
    }
  }
  @supports selector(:focus-visible) {
    .v-btn.v-tab--selected::after {
      content: "";
      position: absolute;
      top: unset;
      bottom: -20%;
      left: 50%;
      width: 0px;
      height: 0px;
      opacity: 1;
      border: 8px solid;
      border-color: white white transparent transparent;
      transform: rotate(135deg);
    }
  }
  .v-tab:last-child {
    border-right: 2px solid white;
  }
  .v-label {
    opacity: 1;
  }
  .v-icon {
    opacity: 1;
  }
  label b.font-weight-bold {
    font-size: 1.1rem;
  }

  .form-bg {
    background-color: rgb(0 0 0 / 60%);
    max-width: 570px;
  }

  form {
    width: 100%;
    max-width: 550px;
  }

  .v-field {
    margin-top: 10px;

    .v-field__append-inner {
      margin-right: -9px;

      button {
        padding: 0px 60px;
      }
    }
  }
}

.for-mobile {
  display: none;
}

@media (max-width: 576px) {
  .top-header {
    h1.copy {
      color: white;
      font-size: 1.2rem;
    }
    h2.subtitle {
      color: white;
      font-size: 0.7rem;
      line-height: 2rem;
    }

    .v-field {
      .v-field__append-inner {
        button {
          padding: 0px 40px;
        }
      }
    }

    .form-bg {
      background-color: rgb(0 0 0 / 60%);
      max-width: 360px;
      width: 360px;
    }

    form {
      width: 100%;
      max-width: 350px;
    }
  }

  .for-mobile {
    display: block;
  }

  .for-desktop {
    display: none;
  }
}
</style>
